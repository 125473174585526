export const ROOT = `/`;
export const LOGIN = `/login`;
export const CLIENTS = `/clients`;
export const CORE_LOGIC_DISCLAIMER = `/core-logic-disclaimer`;

export const CLIENT = `/client/:clientId`;
export const CLIENT_PROFILE = `${CLIENT}/profile`;
export const PROPERTY_ID = `property/:propertyId`;

/**
 * PREFERENCES
 */
export const PREFERENCES = `${CLIENT}/preferences`;
export const PREFERENCES_ROUTE = `${PREFERENCES}/*`;

export const PREFERENCES_ONBOARDING_ROUTE = `onboarding`;
export const PREFERENCES_FEEDBACK_ROUTE = `game-plan-feedback/:gamePlanFeedbackId`;

export const PREFERENCES_ONBOARDING = `${PREFERENCES}/${PREFERENCES_ONBOARDING_ROUTE}`;
export const PREFERENCES_FEEDBACK = `${PREFERENCES}/${PREFERENCES_FEEDBACK_ROUTE}`;

/**
 * GAME PLAN
 */
export const GAME_PLAN = `${CLIENT}/game-plan`;
export const GAME_PLAN_COMPARISON = `${GAME_PLAN}/comparison`;
export const GAME_PLAN_BREAKDOWN = `${GAME_PLAN}/:gamePlanOptionId/breakdown`;

/**
 * ACTIVE PORTFOLIO
 */
export const ACTIVE_PORTFOLIO = `${CLIENT}/active-portfolio`;
export const ACTIVE_PORTFOLIO_PROPERTY_VIEW = `${ACTIVE_PORTFOLIO}/${PROPERTY_ID}`;

/**
 * NOTIFICATIONS
 */
export const NOTIFICATIONS = `${CLIENT}/notifications`;

/**
 * PURCHASE
 */
export const PROPERTIES = `${CLIENT}/properties`;
export const PROPERTIES_PURCHASE = `${PROPERTIES}/purchase/:purchaseId`;
export const PROPERTIES_PURCHASE_ROUTE = `${PROPERTIES_PURCHASE}/*`;

export const PROPERTIES_PURCHASE_THE_NUMBERS_ROUTE = `the-numbers`;
export const PROPERTIES_PURCHASE_REVIEW_THE_BRIEF_ROUTE = `review-the-brief`;
export const PROPERTIES_PURCHASE_PRE_APPROVAL_ROUTE = `pre-approval`;
export const PROPERTIES_PURCHASE_ID_VERIFICATION_ROUTE = `provide-id`;
export const PROPERTIES_PURCHASE_PURCHASE_AGREEMENT_ROUTE = `purchase-agreement`;
export const PROPERTIES_PURCHASE_PAYMENT_ROUTE = `payment`;
export const PROPERTIES_PURCHASE_YOUR_MARKET_ROUTE = `your-market`;
export const PROPERTIES_PURCHASE_SUBURBS_ROUTE = `your-suburbs`;
export const PROPERTIES_PURCHASE_BRIEF_REQUIREMENTS_ROUTE = `brief-requirements`;
export const PROPERTIES_PURCHASE_SEARCH_TRACKER_ROUTE = `search-tracker`;

export const PROPERTIES_PURCHASE_THE_NUMBERS = `${PROPERTIES_PURCHASE}/${PROPERTIES_PURCHASE_THE_NUMBERS_ROUTE}`;
export const PROPERTIES_PURCHASE_REVIEW_THE_BRIEF = `${PROPERTIES_PURCHASE}/${PROPERTIES_PURCHASE_REVIEW_THE_BRIEF_ROUTE}`;
export const PROPERTIES_PURCHASE_PRE_APPROVAL = `${PROPERTIES_PURCHASE}/${PROPERTIES_PURCHASE_PRE_APPROVAL_ROUTE}`;
export const PROPERTIES_PURCHASE_ID_VERIFICATION = `${PROPERTIES_PURCHASE}/${PROPERTIES_PURCHASE_ID_VERIFICATION_ROUTE}`;
export const PROPERTIES_PURCHASE_PURCHASE_AGREEMENT = `${PROPERTIES_PURCHASE}/${PROPERTIES_PURCHASE_PURCHASE_AGREEMENT_ROUTE}`;
export const PROPERTIES_PURCHASE_PAYMENT = `${PROPERTIES_PURCHASE}/${PROPERTIES_PURCHASE_PAYMENT_ROUTE}`;
export const PROPERTIES_PURCHASE_YOUR_MARKET = `${PROPERTIES_PURCHASE}/${PROPERTIES_PURCHASE_YOUR_MARKET_ROUTE}`;
export const PROPERTIES_PURCHASE_SUBURBS = `${PROPERTIES_PURCHASE}/${PROPERTIES_PURCHASE_SUBURBS_ROUTE}`;
export const PROPERTIES_PURCHASE_BRIEF_REQUIREMENTS = `${PROPERTIES_PURCHASE}/${PROPERTIES_PURCHASE_BRIEF_REQUIREMENTS_ROUTE}`;
export const PROPERTIES_PURCHASE_SEARCH_TRACKER = `${PROPERTIES_PURCHASE}/${PROPERTIES_PURCHASE_SEARCH_TRACKER_ROUTE}`;

export const PROPERTIES_PURCHASE_PROPERTY_ROUTE = `${PROPERTIES_PURCHASE}/property/:coreLogicPropertyId/*`;
export const PROPERTIES_PURCHASE_PROPERTY_PHOTOS_ROUTE = `photos`;
export const PROPERTIES_PURCHASE_PROPERTY_INITIAL_APPRAISAL_ROUTE = `initial-appraisal`;
export const PROPERTIES_PURCHASE_PROPERTY_SWOT_ANALYSIS_ROUTE = `swot-analysis`;
export const PROPERTIES_PURCHASE_PROPERTY_CHANCE_OF_BUYING_ROUTE = `chance-of-buying`;
export const PROPERTIES_PURCHASE_PROPERTY_GENERAL_TERMS_ROUTE = `general-terms`;
export const PROPERTIES_PURCHASE_PROPERTY_OUR_THOUGHTS_ROUTE = `our-thoughts`;
export const PROPERTIES_PURCHASE_PROPERTY_LOCAL_AMENITIES_ROUTE = `local-amenities`;
export const PROPERTIES_PURCHASE_PROPERTY_SCHOOLS_RESEARCH_ROUTE = `schools-research`;
export const PROPERTIES_PURCHASE_PROPERTY_DEVELOPMENT_APPLICATIONS_ROUTE = `development-applications`;
export const PROPERTIES_PURCHASE_PROPERTY_RISK_CONSIDERATIONS_ROUTE = `risk-considerations`;
export const PROPERTIES_PURCHASE_PROPERTY_NEIGHBOURS_ROUTE = `neighbours`;
export const PROPERTIES_PURCHASE_PROPERTY_CONSTRUCTION_ROUTE = `construction`;
export const PROPERTIES_PURCHASE_PROPERTY_COMPARABLE_SALES_ROUTE = `comparable-sales`;
export const PROPERTIES_PURCHASE_PROPERTY_PURCHASE_STRATEGY_ROUTE = `purchase-strategy`;
export const PROPERTIES_PURCHASE_PROPERTY_FINAL_APPRAISAL_ROUTE = `final-appraisal`;
export const PROPERTIES_PURCHASE_PROPERTY_PURCHASE_TERMS_ROUTE = `purchase-terms`;
export const PROPERTIES_PURCHASE_PROPERTY_PRE_PURCHASE_REPORTS_ROUTE = `pre-purchase-reports`;
export const PROPERTIES_PURCHASE_PROPERTY_CONTRACT_OF_SALE_ROUTE = `contract-of-sale`;
export const PROPERTIES_PURCHASE_PROPERTY_PREPARE_DEPOSIT_ROUTE = `prepare-deposit`;
export const PROPERTIES_PURCHASE_PROPERTY_AUTHORITY_TO_BID_ROUTE = `authority-to-bid`;
export const PROPERTIES_PURCHASE_PROPERTY_NEGOTIATIONS_ROUTE = `negotiations`;
export const PROPERTIES_PURCHASE_PROPERTY_AUCTION_ADVICE_ROUTE = `auction-advice`;
export const PROPERTIES_PURCHASE_PROPERTY_AUCTION_DAY_ROUTE = `auction-day`;
export const PROPERTIES_PURCHASE_PROPERTY_FINAL_OUTCOME_ROUTE = `final-outcome`;
export const PROPERTIES_PURCHASE_PROPERTY_SIGN_CONTRACT_ROUTE = `sign-contract`;
export const PROPERTIES_PURCHASE_PROPERTY_PAY_DEPOSIT_ROUTE = `pay-deposit`;
export const PROPERTIES_PURCHASE_PROPERTY_UNCONDITIONAL_EXCHANGE_ROUTE = `unconditional-exchange`;
export const PROPERTIES_PURCHASE_PROPERTY_PAY_INSURANCE_ROUTE = `pay-insurance`;
export const PROPERTIES_PURCHASE_PROPERTY_FINANCE_APPROVAL_ROUTE = `finance-approval`;
export const PROPERTIES_PURCHASE_PROPERTY_POST_EXCHANGE_ROUTE = `post-exchange`;
export const PROPERTIES_PURCHASE_PROPERTY_PRE_SETTLEMENT_INSPECTION_ROUTE = `pre-settlement-inspection`;
export const PROPERTIES_PURCHASE_PROPERTY_SETTLEMENT_ROUTE = `settlement`;
export const PROPERTIES_PURCHASE_PROPERTY_PREPARE_INITIAL_DEPOSIT_ROUTE = `prepare-initial-deposit`;
export const PROPERTIES_PURCHASE_PROPERTY_PAY_INITIAL_DEPOSIT_ROUTE = `pay-initial-deposit`;
export const PROPERTIES_PURCHASE_PROPERTY_PREPARE_REMAINING_DEPOSIT_ROUTE = `prepare-remaining-deposit`;
export const PROPERTIES_PURCHASE_PROPERTY_PAY_REMAINING_DEPOSIT_ROUTE = `pay-remaining-deposit`;
export const PROPERTIES_PURCHASE_PROPERTY_CONDITIONAL_EXCHANGE_ROUTE = `conditional-exchange`;
export const PROPERTIES_PURCHASE_PROPERTY_COOLING_OFF_PERIOD_ROUTE = `cooling-off-period`;

export const PROPERTIES_PURCHASE_PROPERTY = `${PROPERTIES_PURCHASE}/property/:coreLogicPropertyId`;
export const PROPERTIES_PURCHASE_PROPERTY_PHOTOS = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_PHOTOS_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_INITIAL_APPRAISAL = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_INITIAL_APPRAISAL_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_SWOT_ANALYSIS = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_SWOT_ANALYSIS_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_CHANCE_OF_BUYING = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_CHANCE_OF_BUYING_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_GENERAL_TERMS = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_GENERAL_TERMS_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_OUR_THOUGHTS = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_OUR_THOUGHTS_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_LOCAL_AMENITIES = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_LOCAL_AMENITIES_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_SCHOOLS_RESEARCH = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_SCHOOLS_RESEARCH_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_DEVELOPMENT_APPLICATIONS = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_DEVELOPMENT_APPLICATIONS_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_RISK_CONSIDERATIONS = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_RISK_CONSIDERATIONS_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_NEIGHBOURS = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_NEIGHBOURS_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_CONSTRUCTION = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_CONSTRUCTION_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_COMPARABLE_SALES = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_COMPARABLE_SALES_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_PURCHASE_STRATEGY = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_PURCHASE_STRATEGY_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_FINAL_APPRAISAL = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_FINAL_APPRAISAL_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_PURCHASE_TERMS = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_PURCHASE_TERMS_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_PRE_PURCHASE_REPORTS = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_PRE_PURCHASE_REPORTS_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_CONTRACT_OF_SALE = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_CONTRACT_OF_SALE_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_PREPARE_DEPOSIT = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_PREPARE_DEPOSIT_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_AUTHORITY_TO_BID = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_AUTHORITY_TO_BID_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_NEGOTIATIONS = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_NEGOTIATIONS_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_AUCTION_ADVICE = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_AUCTION_ADVICE_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_AUCTION_DAY = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_AUCTION_DAY_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_FINAL_OUTCOME = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_FINAL_OUTCOME_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_SIGN_CONTRACT = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_SIGN_CONTRACT_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_PAY_DEPOSIT = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_PAY_DEPOSIT_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_UNCONDITIONAL_EXCHANGE = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_UNCONDITIONAL_EXCHANGE_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_PAY_INSURANCE = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_PAY_INSURANCE_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_FINANCE_APPROVAL = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_FINANCE_APPROVAL_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_POST_EXCHANGE = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_POST_EXCHANGE_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_PRE_SETTLEMENT_INSPECTION = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_PRE_SETTLEMENT_INSPECTION_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_SETTLEMENT = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_SETTLEMENT_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_PREPARE_INITIAL_DEPOSIT = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_PREPARE_INITIAL_DEPOSIT_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_PAY_INITIAL_DEPOSIT = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_PAY_INITIAL_DEPOSIT_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_PREPARE_REMAINING_DEPOSIT = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_PREPARE_REMAINING_DEPOSIT_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_PAY_REMAINING_DEPOSIT = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_PAY_REMAINING_DEPOSIT_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_CONDITIONAL_EXCHANGE = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_CONDITIONAL_EXCHANGE_ROUTE}`;
export const PROPERTIES_PURCHASE_PROPERTY_COOLING_OFF_PERIOD = `${PROPERTIES_PURCHASE_PROPERTY}/${PROPERTIES_PURCHASE_PROPERTY_COOLING_OFF_PERIOD_ROUTE}`;

/**
 * SUBURB
 */
export const SUBURB = `/suburb`;
export const SUBURB_ROUTE = `${SUBURB}/*`;

export const SUBURB_NEW_ROUTE = `new`;
export const SUBURB_EDIT_ROUTE = `:suburbId`;

export const SUBURB_NEW = `${SUBURB}/${SUBURB_NEW_ROUTE}`;
export const SUBURB_EDIT = `${SUBURB}/${SUBURB_EDIT_ROUTE}`;

/**
 * COUPON
 */
export const COUPON = `/coupon`;

/**
 * PROFESSIONAL SERVICE
 */
export const PROFESSIONAL_SERVICE = `/professional-service`;
