import { Modal } from "@mc/components/modal/modal";
import { ButtonLink, PrimaryLoadingButton } from "@mc/components/buttons";
import { H3, PStyled } from "@mc/components/core/typography.styles";
import { ModalStackedActions } from "@mc/components/modal/modal.styles";
import { useMutation } from "@apollo/client";
import useProperty from "../use-property";
import { useNotification } from "@mc/components/notification/notification.context";
import { PUBLISH_PURCHASE_TRACKER_STAGE_OR_ITEM } from "./publish-stage-modal.gql";
import {
  PublishPurchaseTrackerStageOrItemMutation,
  PublishPurchaseTrackerStageOrItemMutationVariables,
  PurchaseTrackerStageType,
} from "@mc/common/gql/types";
import { purchaseTrackerStageTypeMap } from "@mc/common/utils/enum-maps";
import { CheckboxInput, CheckboxInputWrapper } from "@mc/components/inputs/checkbox.styles";
import { useState } from "react";

type PublishStageModalProps = {
  isVisible: boolean;
  close: () => void;
  stageType: PurchaseTrackerStageType;
};

const PublishStageModal = ({ isVisible, close, stageType }: PublishStageModalProps) => {
  const { data } = useProperty();

  const { showSuccess } = useNotification();

  const [publish] = useMutation<
    PublishPurchaseTrackerStageOrItemMutation,
    PublishPurchaseTrackerStageOrItemMutationVariables
  >(PUBLISH_PURCHASE_TRACKER_STAGE_OR_ITEM, {
    onCompleted: () => {
      showSuccess("Published");
      close();
    },
  });

  const [triggerEmail, setTriggerEmail] = useState<boolean>(true);
  const stageTypeLabel = purchaseTrackerStageTypeMap[stageType].label;

  return (
    <Modal isVisible={isVisible} close={close}>
      <H3>Publish stage {stageTypeLabel}</H3>
      <ModalStackedActions>
        <PStyled>
          Are you sure you want to publish Purchase Tracker Stage {stageTypeLabel}? Once confirmed, we will publish all
          steps.
        </PStyled>

        <CheckboxInputWrapper htmlFor="publishCheck" key="publishCheck">
          <CheckboxInput
            id="publishCheck"
            checked={triggerEmail}
            onChange={(e) => {
              setTriggerEmail(e.target.checked);
            }}
          />
          <span>Send email notification to client</span>
        </CheckboxInputWrapper>

        <PrimaryLoadingButton
          type="button"
          isLoading={false}
          onClick={() => {
            publish({
              variables: {
                shortlistPropertyId: data?.purchase.shortlistProperty?._id || "",
                stageType,
                triggerEmail,
              },
            });
          }}
        >
          Confirm
        </PrimaryLoadingButton>
        <ButtonLink $hideUnderline onClick={() => close()}>
          Cancel
        </ButtonLink>
      </ModalStackedActions>
    </Modal>
  );
};

export default PublishStageModal;
