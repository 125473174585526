import { generatePath, useParams } from "react-router-dom";
import useProperty from "./use-property";
import * as Styled from "./purchase-tracker-sidebar.styles";
import { Accordion } from "@mc/components/accordion/accordion";
import { StepList, StepItem, StepItemLink, StepAccordionToggle } from "@mc/components/step-list/step-list";
import PublishStageModal from "./publish-stage-modal/publish-stage-modal";
import { useModalState } from "@mc/components/modal/modal";
import { useState } from "react";
import usePropertyPath from "./use-property-path";
import { PROPERTIES_PURCHASE_SEARCH_TRACKER } from "../../../../../routes.constants";
import { PurchaseTrackerStageType } from "@mc/common/gql/types";

const PurchaseTrackerSidebar = () => {
  const { clientId, purchaseId } = useParams();

  const { data } = useProperty();

  const {
    isVisible: isPublishStageModalVisible,
    open: openPublishStageModal,
    close: closePublishStageModal,
  } = useModalState();

  const [publishingStageType, setPublishingStageType] = useState<PurchaseTrackerStageType | null>(null);

  const shortlistProperty = data?.purchase.shortlistProperty;

  const propertyPath = usePropertyPath(shortlistProperty?.details.id);

  if (!shortlistProperty) return null;

  const stages = shortlistProperty.stages;

  return (
    <Styled.Nav>
      <Styled.NavHeader>
        <Styled.NavAnchor
          to={generatePath(PROPERTIES_PURCHASE_SEARCH_TRACKER, { clientId, purchaseId })}
          $fontWeight="normal"
          $marginLeft={8}
        >
          <Styled.NavIcon id="arrow-left" />
          Back to Search Tracker
        </Styled.NavAnchor>
      </Styled.NavHeader>
      <Accordion id="purchase-tracker">
        {stages?.map((stage) => (
          <StepAccordionToggle key={stage.type} title={stage.label} defaultExpanded isComplete={false}>
            <StepList>
              {stage.items.map((item) => (
                <StepItem key={item.type} purchaseTrackerItemStatus={item.status}>
                  <StepItemLink to={`${propertyPath}/${item.slug}`} label={item.label} />
                </StepItem>
              ))}
            </StepList>
            {[PurchaseTrackerStageType.REVIEW, PurchaseTrackerStageType.DUE_DILIGENCE].includes(stage.type) && (
              <Styled.PublishButton
                type="button"
                onClick={() => {
                  openPublishStageModal();
                  setPublishingStageType(stage.type);
                }}
              >
                Publish
              </Styled.PublishButton>
            )}
          </StepAccordionToggle>
        ))}
      </Accordion>
      {publishingStageType && (
        <PublishStageModal
          isVisible={isPublishStageModalVisible}
          close={closePublishStageModal}
          stageType={publishingStageType}
        />
      )}
    </Styled.Nav>
  );
};

export default PurchaseTrackerSidebar;
