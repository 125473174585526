import { useQuery } from "@apollo/client";
import { GetCoreLogicPropertyDetailsQuery, GetCoreLogicPropertyDetailsQueryVariables } from "@mc/common/gql/types";
import { useParams } from "react-router-dom";
import { GET_CORELOGIC_PROPERTY_DETAILS } from "./property.gql";

type UsePropertyArgs = {
  propertyId?: string;
  onCompleted?: (data: GetCoreLogicPropertyDetailsQuery) => void;
};

export default function useProperty(args?: UsePropertyArgs) {
  const { propertyId, onCompleted } = args ?? {};

  const { purchaseId, coreLogicPropertyId } = useParams<{
    purchaseId: string;
    coreLogicPropertyId: string;
  }>();

  const queryResult = useQuery<GetCoreLogicPropertyDetailsQuery, GetCoreLogicPropertyDetailsQueryVariables>(
    GET_CORELOGIC_PROPERTY_DETAILS,
    {
      variables: { purchaseId: purchaseId!, coreLogicPropertyId: Number(propertyId ?? coreLogicPropertyId) },
      skip: !(purchaseId && (coreLogicPropertyId || propertyId)),
      onCompleted,
    }
  );

  return queryResult;
}
