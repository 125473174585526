import { generatePath, useParams } from "react-router-dom";
import { PROPERTIES_PURCHASE_PROPERTY } from "../../../../../routes.constants";

export default function usePropertyPath(coreLogicPropertyId?: string) {
  const { clientId, purchaseId } = useParams<{
    clientId: string;
    purchaseId: string;
  }>();

  const purchaseUrl = generatePath(PROPERTIES_PURCHASE_PROPERTY, {
    clientId: clientId || "",
    purchaseId: purchaseId || "",
    coreLogicPropertyId: coreLogicPropertyId ?? "",
  });

  return purchaseUrl;
}
