import { useContext, useEffect } from "react";
import MapContext from "./map.context";

type Props = {
  streetViewOptions?: google.maps.StreetViewPanoramaOptions;
};

const StreetView = ({ streetViewOptions }: Props) => {
  const map = useContext(MapContext);

  useEffect(() => {
    if (map) map.getStreetView().setOptions({ ...streetViewOptions });
  }, [streetViewOptions]);

  return null;
};

export default StreetView;
