import { gql } from "@apollo/client";
import { PURCHASE_FRAGMENT } from "../../properties.gql";

export const SHORTLIST_PROPERTY_STAGES_FRAGMENT = gql`
  fragment ShortlistPropertyStagesFragment on ShortlistProperty {
    stages {
      number
      type
      label
      items {
        type
        label
        slug
        status
      }
    }
  }
`;

export const SHORTLIST_PROPERTY_FRAGMENT = gql`
  fragment ShortlistPropertyFragment on ShortlistProperty {
    _id
    status
    isOffMarket
    askingPrice {
      max {
        amount
      }
      min {
        amount
      }
    }
    shortlistReasons
    shortlistNotes
    propertyScore {
      _id
      location
      optionsAvailable
      character
      property
    }
    inspectionDate {
      origin
      formatted(format: "YYYY-MM-DDTHH:mm")
    }
    saleMethod
    saleOfferType
    auctionDate {
      origin
      formatted(format: "YYYY-MM-DDTHH:mm")
      alt: formatted(format: "DD MMM YYYY")
    }
    details {
      id
      coreAttributes {
        id
        beds
        baths
        carSpaces
        landArea
        propertyType
      }
      images {
        id
        defaultImage {
          mediumPhotoUrl
        }
      }
      location {
        id
        singleLine
        latitude
        longitude
        street {
          nameAndNumber
        }
      }
      otmSales {
        id
        forSalePropertyCampaign {
          activeCampaign {
            daysOnMarket
          }
        }
      }
    }
    floodOverlayCheck

    ...ShortlistPropertyStagesFragment
  }
  ${SHORTLIST_PROPERTY_STAGES_FRAGMENT}
`;

export const GET_CORELOGIC_PROPERTY_DETAILS = gql`
  query GetCoreLogicPropertyDetails($purchaseId: String!, $coreLogicPropertyId: Int!) {
    coreLogicPropertyDetails(propertyId: $coreLogicPropertyId) {
      id
      coreAttributes {
        id
        beds
        baths
        carSpaces
        landArea
        propertyType
      }
      additionalAttributes {
        id
        yearBuilt
      }
      images {
        id
        defaultImage {
          mediumPhotoUrl
        }
        allImages {
          largePhotoUrl
          mediumPhotoUrl
        }
      }
      location {
        id
        singleLine
        latitude
        longitude
        state
        street {
          nameAndNumber
        }
      }
      lastSales {
        id
        lastSale {
          agencyName
          zoneDescriptionLocal
          saleMethod
          price {
            formatted
          }
          settlementDate {
            formatted(format: "DD MMM YYYY")
          }
          contractDate {
            formatted(format: "DD MMM YYYY")
          }
        }
      }
      otmSales {
        id
        forSalePropertyCampaign {
          activeCampaign {
            advertisementId
            auctionTime
            auctionDate
            priceDescription
            listingMethod
            daysOnMarket
            price
            agency {
              companyName
              phoneNumber
            }
            agent {
              agent
              phoneNumber
            }
          }
        }
      }
    }
    purchase(purchaseId: $purchaseId) {
      ...PurchaseFragment
      shortlistProperty(coreLogicPropertyId: $coreLogicPropertyId) {
        ...ShortlistPropertyFragment
      }
    }
  }
  ${PURCHASE_FRAGMENT}
  ${SHORTLIST_PROPERTY_FRAGMENT}
`;

export const UPDATE_PURCHASE_TRACKER_PROPERTY_STAGE_OR_ITEM_STATUS = gql`
  mutation UpdatePurchaseTrackerPropertyStageOrItemStatus(
    $shortlistPropertyId: String!
    $itemStatus: PurchaseTrackerItemStatus!
    $stageType: PurchaseTrackerStageType
    $itemType: PurchaseTrackerItemType
  ) {
    updatePurchaseTrackerPropertyStageOrItemStatus(
      shortlistPropertyId: $shortlistPropertyId
      itemStatus: $itemStatus
      stageType: $stageType
      itemType: $itemType
    ) {
      ...ShortlistPropertyFragment
    }
  }
  ${SHORTLIST_PROPERTY_FRAGMENT}
`;
